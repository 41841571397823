<template>
    <div class="app-box">
        <router-view/>
    </div>
</template>

<script setup>
import {inject, onBeforeMount, onMounted} from 'vue';
import {ElNotification} from 'element-plus'
import {createVNode} from 'vue'

const socket = inject('socket');
onBeforeMount(() => {
  if (localStorage.getItem('user_info')) {
    let user_code = JSON.parse(localStorage.getItem('user_info')).user_code
    socket.subscribe('connect', () => {
      socket.emit('login', user_code);
    })

    socket.subscribe('new_msg', (data) => {//后端约定好的钩子
      open(data)
    })
  }
});

let notificationIdCounter = 0
let notifications = []

const open = (data) => {
  let notificationId = notificationIdCounter++
  let notify = ElNotification({
    title: '新消息',
    iconClass: 'el-icon-message-solid',
    customClass: 'notify-zsy',
    dangerouslyUseHTMLString: true,
    showClose: false,
    message: createVNode('div', {
      class: 'personnel'
    }, [
      createVNode('p', {class: 'isCreate'}, data),

      createVNode('div', {class: 'emptyBox'}),

      createVNode('div', {class: 'personnel-btn'}, [
        createVNode('div', {
          class: 'btn',
          onClick: () => userClose(notificationId),
        }, '已读')
      ])
    ]),
    offset: 10,  // 向下偏移10
    duration: 0  // 设置不会自动关闭
  })
  notifications.push({id: notificationId, instance: notify}) // 将通知对象添加到列表
}

const userClose = (id) => {
  notifications.forEach((item, index) => {
    if (id === item.id) {
      item.instance.close()
      notifications.splice(index, 1)
    }
  })
}

//解决element plus滑动窗口时出现报错
const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
}

</script>

<style lang="less">
@import url('@/assets/css/common');

.app-box{
    font-family: Arial, sans-serif!important;
}

.el-aside {
  flex: 0 0 220px;
  width: 220px;
  padding-right: 10px;
  background: white;
  height: auto;
}

.isCreate {
  font-size: 16px;
}

.emptyBox {
  height: 20px;
  width: 20px;
}

.personnel-btn {
  position: absolute;
  bottom: 8px;
  right: 26px;

  .btn {
    padding: 0px 8px;
    color: #666666;
    border: 1px solid #e9e9eb;
    border-radius: 4px;
    cursor: pointer;
  }
}

.notify-zsy {
  color: #E6A23C !important;
  width: 400px !important;
}

.notify-zsy:hover {
  background: #ECF5FF !important;
}

::v-deep .el-dialog {
    max-height: calc(100vh - 20px)!important;
    overflow-y: auto!important;
}

#nprogress .bar {
    background-color: #D3D3D3 !important;
}
</style>
